import urlcat from 'urlcat';
import http from '../http';

export const fetchLocations = query => http.get(urlcat('/locations', query));

export const fetchPaginatedRouteLocations = query =>
  http.get(urlcat('/route-location-maps-paginated', query));

export const exportLocations = query =>
  http.get(urlcat('/locations/export', query));
